import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";
import VueGtag from "vue-gtag";

const Home = () => import(/* webpackChunkName: "home" */ "./components/Home.vue");

const Relatorio = () => import(/* webpackChunkName: "relatorio" */ "./components/Relatorio.vue");

let scrollHome = 0;

const app = createApp(App);

app.config.globalProperties.$projetos = []; //Courses DB
app.config.globalProperties.$painelUrl = process.env.NODE_ENV === 'development' ? 'https://www.transborda60.olabi.org.br/painel/' : './painel/'; //Painel WP

/* VUE ROUTER */
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/pt",
            name: "inicio",
            meta: {
                lang: 'pt',
                pt: '/pt',
                en: '/en',
                rel: '/pt/relatorio'
            },
            components: {
                Screen: Home
            }
        },
        {
            path: "/en",
            name: "home",
            meta: {
                lang: 'en',
                pt: '/pt',
                en: '/en',
                rel: '/en/report'
            },
            components: {
                Screen: Home
            }
        },
        {
            path: "/pt/relatorio",
            name: "relatorio",
            meta: {
                lang: 'pt',
                pt: '/pt/relatorio',
                en: '/en/report'
            },
            components: {
                Screen: Relatorio
            }
        },
        {
            path: "/en/report",
            name: "report",
            meta: {
                lang: 'en',
                pt: '/pt/relatorio',
                en: '/en/report'
            },
            components: {
                Screen: Relatorio
            }
        },
        {
            path: "/:pathMatch(.*)*",
            redirect: () => {
                return { path: '/pt', query: null }
            },
        }
    ],
    scrollBehavior() {
        if (router.currentRoute.value.path === "/") {
            return { top: scrollHome }
        }
        return { top: 0 };
    }
});

/* LOGIN REDIRECT */
router.beforeEach( to => {
    gtag('event', 'page_view', { //eslint-disable-line
        page_title: to.name,
        page_location: to.path
    });
});

app.use(router);
app.use(VueGtag, {
    /* includes: [
        { id: '' }, //ADS
    ], */
    config: { id: 'G-7254WE659B' } //Analytics ou GTM
});
router.isReady().then(() => app.mount("#app"));